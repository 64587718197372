@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;600;700;900&display=swap");

* {
    font-family: "Titillium Web";
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 14px;
}

.fomullar-heading-txt {
    font-weight: 700;
}
.close {
    width: 25px;
    background-color: transparent;
    border: none !important;
    span {
        font-size: 25px;
    }
}
.fomullar-heading {
    border-radius: 15px;
    background-color: #db7c1d;
    font-size: 20px;

    @media screen and (max-width: 991.98px) {
        font-size: 18px;
    }
    @media screen and (max-width: 575px) {
        font-size: 15px;
    }
    .heading-txt {
        font-weight: 700;
        font-size: 16px;
    }
}

.formsmtext {
    font-size: 16px !important;
}

.formular-smtext {
    font-weight: 700;
    font-size: 18px;
    @media screen and (max-width: 767.98px) {
        font-size: 15px;
    }
    @media screen and (max-width: 575px) {
        font-size: 12px;
    }
}

.pointer-cursor {
    cursor: pointer;
}

.label {
    &__head {
        font-weight: 700;
        font-size: 32.2px;
        @media (max-width: 991.98px) {
            font-size: 25px;
        }
        @media (max-width: 499.98px) {
            font-size: 22px;
        }
    }

    &__text {
        font-weight: 700;
        font-size: 18px !important;
        @media (max-width: 991.98px) {
            font-size: 15px;
        }

        @media screen and (max-width: 400px) {
            font-size: 12px !important;
            margin-right: 5px !important;
            margin-left: 5px !important;
        }
    }
}

.checkboxhandle {
    vertical-align: 0 !important;
}

.input-handle {
    .text-field {
        color: rgb(48, 47, 47);
        font-weight: 600;
        background-color: #ebebeb;
        border-radius: 10px;
    }
}

.btn-fomullar {
    width: 100%;
    height: 49px;
    border-radius: 10px;
    background-color: #1dcb43;
    border: none;
    font-weight: 700;
    font-size: 30px;
    color: white;
}

.testimonials {
    &__cards {
        border-radius: 20px;
        height: 229px;
        filter: drop-shadow(0 0 32px rgba(22, 28, 45, 0.05));
        background-color: #ffffff;
        border: 1px solid #e7eaf3;
        display: flex;
        justify-content: center;

        &-heart {
            top: -4%;
            left: 85%;

            @media screen and (max-width: 1024px) {
                top: 8%;
                left: 85%;
            }

            @media screen and (max-width: 768px) {
                top: 10%;
                left: 85%;
            }

            @media screen and (max-width: 575px) {
                top: 23%;
                left: 85%;
            }

            @media screen and (max-width: 575px) {
                top: 12%;
                left: 85%;
            }
        }
    }
}

.font-weight-bold {
    font-size: 30px;
    color: #ffffff;
    font-weight: 700 !important;
    @media screen and (max-width: 767.98px) {
        font-size: 27px;
    }
    @media screen and (max-width: 575px) {
        font-size: 24px;
    }
}

.hero-contents {
    &__bg {
        background-color: #3c8ac8;
        color: white;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        z-index: 1;

        @media screen and (max-width: 991.98px) {
            border-radius: 0 !important;
        }

        @media screen and (max-width: 576.98px) {
            padding: 30px 40px !important;
            border-radius: 0% !important;
        }
    }

    &__title {
        font-size: 40px;
        font-weight: 700;
        @media (max-width: 991.98px) {
            font-size: 32px;
        }
        @media (max-width: 576.98px) {
            font-size: 35px;
        }
    }

    &__text {
        font-size: 22px;
        font-weight: 400;
        line-height: 22px;
        @media (max-width: 991.98px) {
            font-size: 18px;
        }
    }

    &__smtext {
        font-size: 18px;
        font-weight: 400;
        margin-left: 4px;
        @media screen and (max-width: 576px) {
            font-size: 15px;
        }
    }
    &__smtext2 {
        @media screen and (max-width: 576px) {
            text-align: center;
        }
    }
    &__optext {
        font-size: 14px;
        font-weight: 700;
    }

    &__btn {
        width: 300px !important;
        height: 50px;
        border-radius: 10px;
        font-weight: 700;
        font-size: 28px;
        background-color: #1dcb43;
        border: none;
        color: white;

        @media (max-width: 1024px) {
            width: 300px !important;
            height: 49px;
            font-size: 22px;
        }

        @media (max-width: 575px) {
            width: 100% !important;
            // height: 39px;
            // font-size: 18px;
            // padding: 16px !important;
            margin: auto;
        }
    }

    &__img {
        img {
            margin-left: -2%;
            width: 102% !important;
            height: 100% !important;
            object-fit: cover;

            @media screen and (max-width: 575px) {
                margin-left: 0% !important;
                width: 100% !important;
                height: 100% !important;
            }
        }
    }
    &__sticker {
        position: absolute;
        right: -100px;
        bottom: -30px;
        width: 200px;
        @media (min-width: 922px) and (max-width: 1200px) {
            right: -170px;
            bottom: -60px;
        }
        @media screen and (max-width: 991.98px) {
            bottom: -50px;
            right: -50px;
        }
        @media (max-width: 767.98px) {
            right: 5px;
            bottom: -40px;
            width: 170px;
        }
    }
    &__sticker2 {
        position: absolute;
        right: -19px;
        bottom: -30px;
        width: 103px;
        @media (min-width: 575px) {
            display: none !important;
        }
    }

    &__card {
        background-color: #ebebeb;
    }
}

.cardColor {
    background-color: #555757;
}

.fomullar-contents {
    background-color: #3c8ac8;
    color: white;

    .first-heading {
        font-weight: 600;
        font-size: 32px;
        @media screen and (max-width: 991.98px) {
            font-size: 27px;
        }
        @media screen and (max-width: 575px) {
            font-size: 20px;
        }
    }
    .first-headingBox {
        font-weight: 600;
        font-size: 28px;
        @media screen and (max-width: 991.98px) {
            font-size: 22px;
        }
        @media screen and (max-width: 575px) {
            font-size: 14px;
        }
    }

    .sec-heading {
        font-weight: 700;
        // color: #1dcb43;
        font-size: 24px;
        @media screen and (max-width: 767.98px) {
            font-size: 27px;
        }
        @media screen and (max-width: 575px) {
            font-size: 20px;
        }
    }

    .formPara {
        font-size: 20px !important;
    }

    .first-para {
        border-bottom: 1px solid #ffffff;
    }

    &__btn {
        width: 100%;
        height: 59px;
        border-radius: 10px;
        background-color: #1dcb43;
        border: none;
        color: white;
    }

    &__img {
        position: absolute;
        top: -6% !important;
        left: 82%;
        rotate: -4deg;
        width: auto;

        @media screen and (max-width: 1024px) {
            display: none;
        }
    }
}

.optBtn {
    font-size: 24px;
    font-weight: 700;
    width: 100% !important;
    border-radius: 10px;
    display: block;
    background-color: #1dcb43;
    border: none;
    color: white;
    margin-bottom: 10px !important;
    @media screen and (max-width: 768px) {
        font-size: 18px;
    }
    @media screen and (max-width: 768px) {
        width: 200px !important;
        margin-bottom: 10px !important;
    }
}

.border-right {
    border-right: 2px solid white;
}

.handleFormgroup {
    display: flex;
    align-items: center;
}

.header {
    &__tag {
        color: #3c8ac8;
        font-weight: 700;
        font-size: 28px;
        @media (max-width: 1125.98px) {
            font-size: 23px;
            margin-top: 1rem !important;
        }
        @media (max-width: 991.98px) {
            font-size: 22px;
            margin-top: 1rem !important;
        }
        @media screen and (max-width: 575px) {
            font-size: 22px;
            margin: 0 !important;
        }
        @media screen and (max-width: 345px) {
            font-size: 20px;
        }
    }

    &__logo {
        @media screen and (max-width: 575px) {
            display: flex;
            justify-content: center;
            flex-direction: column;
            margin: 10px 0px;
        }

        img {
            @media (max-width: 991.98px) {
                width: 180px;
            }
            @media screen and (max-width: 575px) {
                width: 200px;
                margin-top: 5px;
            }
        }
    }

    &__main {
        @media screen and (max-width: 575px) {
            position: absolute;
            margin-top: -112px !important;
            padding-right: 0px !important;
        }
    }

    &__stars {
        span {
            margin-top: 5px !important;
            padding-right: 5px !important;

            @media screen and (max-width: 575px) {
                padding-right: 10px !important;
            }
        }
        img {
            @media (max-width: 991.98px) {
                width: 100px;
            }
        }
        @media screen and (min-width: 570px) and (max-width: 770px) {
            margin-bottom: 10px;
        }
        @media screen and (max-width: 575px) {
            margin: 12px;
            margin-bottom: 0;
            justify-content: end !important;
        }
    }
}

.thanks-contents {
    background-color: #3c8ac8;
    color: white;
    font-weight: 600;

    &__thankyes {
        font-weight: 700;
        font-size: 36px;
    }

    &__thankNo {
        font-weight: 700;
        font-size: 36px;
    }

    &__thankNo-text {
        font-weight: 400;
        font-size: 18px;
    }
}

.setbackground-color {
    &__green {
        background-color: rgb(65, 192, 65);
    }

    &__red {
        background-color: rgb(221, 73, 73);
    }
}
.footer {
    a {
        cursor: pointer;
    }
}
